
@import "../../../../sass/variables";

.comment-tab{
.comment-section{

    background-color: rgba($secondary-light, 0.2)!important;
}

.comments-user-content 
{
  height: calc(100vh - 110px);
  
    overflow-y: scroll; // Enables vertical scrolling
    overflow-x: hidden; // Avoids horizontal scrolling

    /* Scrollbar styling */
    &::-webkit-scrollbar {
      width: 4px; /* Scrollbar width */
    }

    &::-webkit-scrollbar-track {
      background-color: rgba($primary, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background: $primary; /* Scrollbar handle color */
      border-radius: 10px; /* Rounded scrollbar handle */
    }
}

.no-comment-sec{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .no-comments-message{
    i{
    font-size: 50px;
    display: flex;
    justify-content: center;
  }
}
}
    
.filter-comment-icon{
    color: #000;
    font-size: 18px;
    padding:3px 8px;
    border-radius: 6px;


    .dropdown-toggle{
        width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 18px;
    }
    .dropdown-toggle::after{
        display: none;
    }

    .dropdown-menu{
        left: -110px!important;
        .dropdown-item{font-size: 12px;}
    }
    }
}

.user-comment-sec{
    cursor: pointer;
    padding: 12px;
    border: 2px solid $white;
    // border-bottom-color: rgba($primary, 0.2);
    &:hover{
        border: 2px solid rgba($primary, 0.2);
        // background-color: rgba($primary, 0.2)!important;
    }

    .check-comment{
        right: 0;
        top: 0;
        font-size: 18px;
        color: $primary;
        cursor: pointer;
    }

   
.avatar-users-sec{
  display: flex;
  align-items: flex-start;
  justify-content: start;
  
.avatar-user{
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($secondary-light, 0.4)!important;
    color: $black;
}
}

}

.custom-tooltip {
    .tooltip-arrow {
      left: 50% !important; // Center the arrow relative to the tooltip
      transform: translateX(-50%) !important;
    }
  
    &.bs-tooltip-bottom {
      margin-top: 5px;
    }
  }
  .avatar-circle{
    width: 40px!important;
    height: 40px!important;
  }