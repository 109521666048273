@import "../../../../sass/variables";


// SCSS code
.seg-color-input {
    border-radius: 4px !important;
    border: 1px solid $primary !important;
    background: $primary!important;
    height: 35px !important;
    padding: 0 !important;

    &:hover {
      background: $primary!important;
    }
  }
  
  .color-copy-btn {
    height: 29px;
    padding: 0;
    width: 58px !important;
    left: 19px;
    top: -7px;
    background: transparent !important;
    border: none !important;
  
    &:hover {
      width: 34px !important;
    }
  }
  
  .tool-wrapper {
    padding-left: 0px;
  }
  
  .select-tool-project {
    // background-color: #f1f1f1;
    background-color:rgba(256, 256, 256, 0.7) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(256, 256, 256, 0.9) !important;
    padding: 15px 0px 0px 9px;
    border-radius: 10px;
    margin-bottom: 10px;
  
    div {
      width: 50px;
      padding-bottom: 15px;
  
      i {
        background: #fff;
        padding: 5px 12px;
        font-size: 18px;
        border-radius: 3px !important;
        cursor: pointer;
  
        &:hover {
          background-color: $primary;
          color: #fff !important;
        }
      }
  
      svg:hover {
        background-color: $primary;
        color: #fff !important;
      }
    }
  
    .save-icons {
      svg {
        background: #fff;
        width: 42px;
        padding: 8px;
        height: 34px;
        border-radius: 3px;
        display: flex;
        margin-top: -4px;
        cursor: pointer;
      }
    }
  
    .bi-file-earmark-ppt,
    .bi-info-lg {
      margin-left: 0 !important;
    }
  
    &:hover .save-icon-image-black {
      display: none;
    }
  
    &:hover .save-icon-image-white {
      display: block;
    }
  }
  
  .tool-show-hide-icon {
    padding: 10px 6px;
    border-radius: 4px;
    position: absolute;
    bottom: -113px;
    right: 5px;
  
    i {
      font-size: 20px;
      background: #fff;
      cursor: pointer;
      padding: 3px 8px;
      border-radius: 6px;
  
      &:hover {
        background-color: $primary;
        color: #fff;
      }
    }
  }
  
  .image-tool-save-icon {
    position: absolute;
    top: -42px;
    left: 6px;
  
    img {
      background: #fff;
      cursor: pointer;
      padding: 7px 8px;
      width: 34px;
      border-radius: 4px;
    //   @include box-shadow($box-shadow);
    }
  
    &:hover .save-icon-image-white {
      display: block;
    }
  
    &:hover .save-icon-image-black {
      display: none;
    }
  
    .save-icon-image-white:hover {
      background-color: $primary !important;
    }
  }
  
  .save-combo {
    top: -70px;
  
    i {
      background: #fff;
      padding: 5px 9px;
      font-size: 16px;
      border-radius: 5px !important;
      cursor: pointer;
  
      &:hover {
        background-color: $primary !important;
        color: #fff !important;
      }
    }
  }
  
  .information-text,
  .order-info-select h4 {
    color: #000;
    font-size: 16px;
    // font-family: $font-family;
    font-weight: 500;
  }
  
  .order-info-select {
    display: inline-block;
    width: 100%;
    border: 1px solid $primary;
    border-radius: 3px;
    padding: 15px 0 10px 0;
    margin-left: 5px;
  
    img {
      width: 50%;
      margin: 0 auto;
      padding: 20px 0 10px 0;
    }
  
    p {
      text-align: center;
      background: #293849 !important;
      width: 23px;
      height: 23px;
      margin: 0 auto;
      color: #fff;
      border-radius: 50px;
    }
  
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }
  }
  
  .select-pallet-toolbars {
    position: absolute;
    bottom: 130px;
    right: 20px;
  }
  
  .order-seg-section {
    display: flex;
    margin-bottom: 30px;
  }
  
  .active-icon i {
    background-color: $primary !important;
    color: #fff !important;
  }



.canvas-mode-section{
  position: absolute;
  bottom: -110px;
  right: 700px;
  
  .zoomContainer {
    display: flex;
    
    background-color:rgba(256, 256, 256, 0.7) !important;
    backdrop-filter: blur(10px) !important;
border: 1px solid rgba(256, 256, 256, 0.9) !important;
   
    border-radius: 50px; // "rounded-pill" equivalent
    padding: 0.5rem 1.2rem; // Adjust padding as needed
  
    span {
  
  
      i {
        // Ensure icons have rounded-pill shape
        border-radius: 50%;
        padding: 1px 4px;
        cursor: pointer; // Pointer on hover
        font-size: 16px;
        &:hover {
          background-color:$primary; // Background turns red on hover
          color: #fff; // Optional: Text/icon color changes to white on hover
        }
      }
    }

    .zoom-reset{
        padding: 2px 5px;
        border: 1px solid transparent;
            &:hover{
                border: 1px solid $white;
                padding: 2px 5px;
                border-radius: 6px;
                cursor: pointer;
            }
    }
    h6 {
      margin: 0; // Reset default margins on the <h6> tag
      align-self: center; // Vertically align the text in the flexbox container
    }
  }

  .brightness{
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    background-color:rgba(256, 256, 256, 0.7) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(256, 256, 256, 0.9) !important;
    i{
      color: $black;
    }
  }
}

  

  