@import "../../../sass/variables";

.sidebar{
    position: fixed;
    left: -17px;
    top: 0;
    height: 100vh;
    width: 250px;
    transition: all 0.2s ease-in-out;
    z-index: 1020;
    margin-top: 45px;
    .sidebar-menu{
        // border-radius: 0.5rem;
        //  border-bottom-left-radius:16px;
        border-radius: 0 0.5rem 0 0.5rem;
        box-shadow: 0 6px 16px rgba($black, 0.1);
        .sidebar-header{
            height: 44px;
            .logo{
                width: 120px;
            }
            .tooglebtn{
                width: 44px;
                min-width: 44px;
                height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                // color: $secondary;
                font-size: 20px;
            }
        }
        .sidebar-nav{
            margin: 0;
            padding: 0;
            list-style: none;
            li{
                display: flex;
                flex-direction: column;
                padding: 4px 6px;
                border-radius: 8px;
                position: relative;
                transition: all 0.2s ease-in-out;
                .sidebar-nav-icon{
                    width: 32px;
                    min-width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    font-size: 20px;
                    // color: $secondary-light;
                }
                .dropicon {
                    position: absolute;
                    right: 12px;
                    top: 10px;
                }
                .sidebar-nav-text{
                    font-size: 13px;
                    text-wrap: nowrap;
                }
                
                .submenu{
                    margin: 0 7px 8px;
                    padding: 0;
                    display: none;
                    flex-direction: column;
                    gap: 4px;
                    height: 0;
                    transition: all 0.2s ease-in-out;
                    li{
                        padding: 5px 6px 5px 30px;
                        position: relative;
                        font-size: 13px;
                        cursor: pointer;
                        &::before{
                            width: 4px;
                            min-width: 4px;
                            height: 4px;
                            position: absolute;
                            left: 14px;
                            top: 12px;
                            content: "";
                            border-radius: 50%;
                            background: $primary;
                        }
                        &:hover{
                            background: rgba($white, 0.9);
                            color: $primary;
                        }
                        &.active{
                            background: $primary;
                            color: $white;
                            &::before{
                                background: $white;  
                            }
                        }
                    }
                }

                &.active{
                    background-color: rgba($primary, 0.07);
                    .sidebar-nav-icon{
                        color: $primary;
                    }
                    .dropicon {
                        color: $primary;
                    }
                    .sidebar-nav-text{
                        color: $primary;
                    }
                    .submenu{
                        display: flex;
                        height: auto;
                    }
                }
            }
        }
    }
}

.back-icons{
    cursor: pointer;
    width: 66px;
}
