@import "../../sass/variables";

.pro-images{
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.pro_page{
   .nav-item{
    .nav-link{
        color: $black;
        border: none!important;
        // border-bottom: 1px solid $border!important;
        &.active {
            border-bottom: 2px solid var(--primary)!important;
            background-color: transparent !important;
            color: var(--primary);
          }
    }
   }
}
