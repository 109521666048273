/* src/module/auth/signUp/SignUpForm.scss */
.create-account {
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between form and image */
    padding: 20px;
  
    .sign-up-form {
  
  
      padding: 20px;
      border: 1px solid #ccc;
      border-radius: 8px;
    //   box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    //   background-color: #f9f9f9;
  
      .form-input, .form-select {
        width: 100%;
        padding: 10px;
        margin: 10px 0;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
  
      .submit-button {
        background-color: #4CAF50; /* Green */
        color: white;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
      }
  
    
    }
  
    .image-container {
      flex: 1; /* Allow the image container to take remaining space */
      img {
        max-width: 100%; /* Make the image responsive */
        height: auto; /* Maintain aspect ratio */
        border-radius: 8px; /* Optional: add rounded corners */
      }
    }
  }