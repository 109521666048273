@import "../../sass/variables";

.profile-page {
padding-top: 300px;
  .user-info {
    .icon-container {
      width: 100px; // Fixed width for the icon container
      height: 100px; // Fixed height for the icon container
      border-radius: 50%; // Circular shape
      display: flex; // Flexbox for centering the icon
      align-items: center; // Center icon vertically
      justify-content: center; // Center icon horizontally
      background-color: #fff; // Light gray background for the icon
      margin-right: 15px; // Space between icon and text
      font-size: 50px; // Icon size
      color: #6c757d; // Icon color
      border: 1px solid rgba($primary,0.24);
    }
  }
  .profile-tab {
    width: 200px;
    border: none;
    .nav-item {
      .nav-link {
        width: 150px;
        border-radius: 6px;
        // margin-top: 2px;
        &.active {
          background-color: $primary !important;
          color: #fff !important; // Ensures text color is readable
          &:hover {
            background-color: lighten($primary, 10%) !important; // Optional hover effect
            color: #fff !important;
          }
        }
        &:hover {
            border:1px solid rgba($primary,0.9);
          }

      
      }
    }
  }
}
