@import "../../../../../sass/variables";


.pallet-info{
    padding: 10px;
    width: 200px!important;
    position: absolute;
    left: 0px;
    background: #fff;
    border-radius: 6px;
    border: 1px solid #ddd;
    .seg-pallet-act{
        padding: 0;
        li{
            list-style: none!important;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 18px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: space-between;
          
            &:hover{
                background-color: rgba($light-primary, 0.06);
                // box-shadow: 0 2px 5px rgba($black, 0.1);
            }
            &.active{
                background-color: rgba($primary, 1);
            }
            i{
                padding-right: 8px;
                padding-left: 8px;
                // color: rgba($primary, 0.8);
            }

        }
    }

    .seg-pallet-actions{
        padding: 0;
        li{
            list-style: none!important;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
          
            &:hover{
                background-color: rgba($light-primary, 0.06);
                // box-shadow: 0 2px 5px rgba($black, 0.1);
            }
            &.active{
                background-color: rgba($primary, 1);
            }
            i{
                padding-right: 8px;
                padding-left: 8px;
                // color: rgba($primary, 0.8);
            }

        }
    }

    .dropdown-item{
    font-size: 14px;
    }
}

.popover-select-area{
    .popover-arrow{
        display: none;
    }
}

.pallet-infomation-icon {
    position: absolute;
    top: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    // display: none;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 1px;
    cursor: pointer;
    i {
      font-size: 8px;
      display: flex;
    }
  }


