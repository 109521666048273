.Edit-project-section{ margin: 0 auto;}
.edit-project{margin-top: 30px; padding-top: 30px; padding-bottom: 30px;}
.back-icon{text-align: left; 
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 84px;
    height: 26px;
    margin-top: 30px;
}

    .back-icon i{font-size: 40px; color: #6d455a;}
    .back-icon span{ color: #6d455a;font-family: "Poppins", sans-serif; }
  
    .edit-job-from .back-icon{ display: none;}
    .edit-job-from .card-edit{padding: 0; margin: 0!important;}
    
