@import "../../sass/variables";

.canvas-mainrapper{
    height: 100vh;
    .canvas-left-cat{
        border-right: 1px solid rgba($black, 0.1);
        .canvas-left-header{
            padding: 0;
            .backtohome{
                .logo {
                    width: 32px;
                    height: 32px;
                    background: var(--primary);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 6px;
                    border-radius: 4px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        .canvas-left-body{
            padding: 0;
        }
    }
}



.left-panel-menu{
    height: calc(100vh - 104px);
    border-right: 1px solid rgba($black, 0.1);
    background-color: rgba($primary, 0.1);
    gap: 12px;
    position: fixed;
    left: 0;
    width: 57px;
    .nav-item{
        .nav-link{
            width: 40px;
            max-width: 40px;
            height: 40px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--background);
            border: 1px solid var(--border);
            &:hover{
                border: 1px solid var(--border);
            }
            &.active{
                background-color: var(--primary);
                svg{
                    path{
                        stroke: $white;
                    }
                }
            }
        }
    }
}


.tab-sub-container{
    // padding-left: 57px;
    width: 100%;
    .segment-tab-menu{
        width: 100%;
        background: $white;
        border-bottom: 1px solid rgba($black, 0.1);
        .swiper{
            .swiper-wrapper{
                .swiper-slide{
                    padding: 12px 15px;
                    cursor: pointer;
                    font-weight: 600;
                    // &.swiper-slide-active{
                    //     color: $primary;
                    // }
                }
            }
        }
    }
}

.segment-select-tool-list{
    background: var(--background);
    // height: calc(100vh - 150px);
    // border-right: 1px solid rgba($black, 0.1);
    .nav-link{
        color: $secondary;
        &.active{
            color: $primary;
        }
    }
}

.segmentview-details{
    width: 100%;
    .selected-items{
        background-color: var(--background);
        border-bottom: 1px solid rgba($black, 0.1);
    }
}

.palletsbox{
    position: relative;
    border: 1px solid rgba($black, 0.8);
    padding: 4px;
    background-color: var(--background);
    border-radius: 15px;
    img{
        width: 100%;
        height: 100%;
        border-radius: 10px;
        object-fit: cover;
        display: block;
    }
    .pallets-info {
        position: absolute;
        top: 5px;
        left: 5px;
        background: rgba(0,0,0,0.5);
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid rgba(255,255,255,0.5);
        color: #fff;
         display: none;
        align-items: center;
        justify-content: center;
    }   
     
    &.active{
        img{
            border: 2px solid $primary;
            padding: 1px;
        }
        .checkicon {
            width: 20px;
            height: 20px;
            display: block !important;
            &.md{
                width: 40px;
                height: 40px;
            }
        }
    }
    &:hover{
        // box-shadow: 0 8px 16px rgba($black, 0.1);
        .pallets-info{
            display: flex;
        }
    }
}

.tabs-navigationdd {
    display: flex;
    background: var(--background);
    border-bottom: 1px solid rgba($black, 0.1);
    button {
        width: 100%;
        border: none;
        padding: 5px;
        background: var(--background);
        font-weight: 600;
        &.active{
            color: $primary;
        }
    }
}




/*=========================================*/
  
.checkicon {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 50%;
     display: none;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px $primary;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 1;
    .checkmark__circle {
        stroke-dasharray: 166;
        stroke-dashoffset: 166;
        stroke-width: 2;
        stroke-miterlimit: 10;
        stroke: $white;
        fill: none;
        -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
                animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
    }
    .checkmark__check {
        transform-origin: 50% 50%;
        stroke-dasharray: 48;
        stroke-dashoffset: 48;
        -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
                animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
    }
}
  
  @-webkit-keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none;
    }
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  @-webkit-keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $primary;
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px $primary;
    }
  }


  .swichpallets-box{
    height: calc(100vh - 320px);
    overflow: auto;
    
    &::-webkit-scrollbar {
        width: 4px; /* Scrollbar width */
    }

    &::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primary);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary-dark);
    }
    
}



