.job-loading{background-color: #6440FA;}
.css-afyhf7{background-color: #6440FA;}
.select-create-project-loading .chakra-alert{background-color: #6440FA!important; border-radius: 6px;}
.select-create-project-loading{   
    position: absolute;
    right: 41%!important;
    top: 225px!important;}

    .boxes{margin: 0 auto!important;}
    .job-loading{padding-left: 10px; font-family: "Poppins", sans-serif; font-weight: 500!important; }
    .chakra-alert{padding-left: 10px;}

    /* .select-create-project-loading .css-afyhf7{width: 308px;} */

    /* .select-label-active{background-color: black!important;} */
    /* .css-ez23ye:active, .css-ez23ye[data-active]{background-color: #293849 !important;} */
   /* .select-labels-button .select-label-active .bi:active{background-color: #293849!important;} */

   .select-create-project-loading{    width: 200px!important;
    height: 200px;
    border-radius: 100px;
    padding: 34px 15px 0 0px;

}
/* background: #f1f1f161; */
   




   

   

.boxes {
    height: 32px;
    width: 32px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    margin-top: 32px;
    -webkit-transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
    transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}
.boxes .box {
    width: 32px;
    height: 32px;
    top: 0px;
    left: 0;
    position: absolute;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}



.boxes .box:nth-child(1) {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
    -webkit-animation: box1 1s linear infinite;
    animation: box1 0.8s linear infinite;
}
.boxes .box:nth-child(2) {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    -webkit-animation: box2 1s linear infinite;
    animation: box2 0.8s linear infinite;
}
.boxes .box:nth-child(3) {
    -webkit-transform: translate(100%, 100%);
    transform: translate(100%, 100%);
    -webkit-animation: box3 1s linear infinite;
    animation: box3 0.8s linear infinite;
}
.boxes .box:nth-child(4) {
    -webkit-transform: translate(200%, 0);
    transform: translate(200%, 0);
    -webkit-animation: box4 1s linear infinite;
    animation: box4 0.8s linear infinite;
}



.boxes .box > div {
    background: #5C8DF6;
    --translateZ: 15.5px;
    --rotateY: 0deg;
    --rotateX: 0deg;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #5C8DF6;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    -webkit-transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
    transform: rotateY(var(--rotateY)) rotateX(var(--rotateX)) translateZ(var(--translateZ));
}

.boxes .box > div:nth-child(1) {
    top: 0;
    left: 0;
    background: #6440FA;
    border: 1px solid #f1f1f1;
}
.boxes .box > div:nth-child(2) {
    background: #6440FA;
    right: 0;
    --rotateY: 90deg;
    border: 1px solid #f1f1f1;
}
.boxes .box > div:nth-child(3) {
    background: #6440FA;
    --rotateX: -90deg;
    border: 1px solid #f1f1f1;
}
.boxes .box > div:nth-child(4) {
    background: #f1f1f1c9;
    top: 0;
    left: 0;
    --translateZ: -90px;
    border: 1px solid #6440FA;
}





@keyframes box1 {
    0%,
    50% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(200%, 0);
    }
}

@keyframes box2 {
    0%{
        transform: translate(0, 100%);
    }
    50% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(100%, 0);
    }
}

@keyframes box3 {
    0%,
    50% {
        transform: translate(100%, 100%);
    }
    100% {
        transform: translate(0, 100%);
    }
}

@keyframes box4 {
    0%{
        transform: translate(200%, 0);
    }
    50% {
        transform: translate(200%, 100%);
    }
    100% {
        transform: translate(100%, 100%);
    }
}










