@import "../../../../sass/variables";


.display-mode-sec {
    width: 270px;
    position: absolute;
    bottom: 16px;
    left: 304px;
    // background-color: var(--background);
    background-color:rgba(256, 256, 256, 1) !important;
    backdrop-filter: blur(10px) !important;
    border: 1px solid rgba(256, 256, 256, 1) !important;



    .header-icon {

        cursor: pointer;
        width: 24px;
        height: 24px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: var(--primary) !important;
            border: 1px solid $primary;
        }
        &:active{
            background-color: var(--primary);
        }


    }

    .range {
        height: 40px;

    }
}



.showLabels {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    input[type='checkbox'] {
        /* Hide default checkbox */
        display: none;
    }

    .optionContainer {
        position: relative;
        width: 3.5rem; // w-14
        height: 30px; // h-9



        &::after {
            content: '';
            position: absolute;

            width: 20px;
            height: 20px;
            top: 5px;
            left: 8px;


            background-color: $white;
            border-radius: 50px;
            transition: transform 0.2s ease;
            border: 1px solid var(--primary);
        }
    }

    input:checked+.optionContainer::after {
        transform: translateX(100%); // Move to the right
    }

    .option {
        position: absolute;

        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;

        &.off {
            right: 0.25rem; // Adjust position to the right
            display: none; // Hide by default
        }

        &.on {
            left: 0.25rem; // Adjust position to the left
            display: none; // Hide by default
        }
    }

    /* Show/Hide the eye icons based on checkbox state */
    input:checked~.option.on {
        display: flex; // Show when checked
    }

    input:checked~.option.off {
        display: none; // Hide when checked
    }

    input:not(:checked)~.option.on {
        display: none; // Hide when not checked
    }

    input:not(:checked)~.option.off {
        display: flex; // Show when not checked
    }

    .optionCircle {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 50px;

    }
}

.setting-pop{
    left: 355px !important;
    .form-control{
        width: 25%;
        padding: 0.2rem 0.5rem;
        font-size: 14px;
    }


}

.form-label {
    margin-bottom: 2px;
    color: var(--text);
}

.card-title{
    color: var(--text);
}