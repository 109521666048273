.radio-button{    border: 1px solid #cbd5e0;
    background: #f6f6f9;
    border-radius: 5px; padding-top: 3px; padding-bottom: 3px; padding-left: 10px;}
    .radio-button .css-1bb1pgq{background-color: #fff; padding: 3px 38px; 
    box-shadow: none!important;
    }

    .checkbox-status .css-1bb1pgq{background-color: #fff; padding: 3px 33px; 
        box-shadow: none!important;
        }
    .css-1bb1pgq[aria-checked=true], .css-1bb1pgq[data-checked]{background: #6d455a!important; border-color: #6d455a!important;}