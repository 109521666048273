
@import "../../sass/variables"; 
.generate-img {
    text-align: center;
    background-color: $light-brown; // Direct use of a hex color.
    .proimg{

        text-align: center;
       margin-top: 20px;
        // img{
            
        // }
    }
}

.segbtn{
    display: flex;
    justify-content: space-between;  
    align-items: center;             
    padding: 20px 15px; 

    .segbtn .btn {                      
        flex-grow: 0;  
    }
    
}

.seg-section{
    display: flex;
    justify-content: center;
    margin-top: 30px;
.seg {
    cursor: pointer;
    margin-left: 8px;
    text-decoration: none;
    .segicon {
        background-color:  $dark-gray; 
        width: 90px;
        height: 90px;
        border-radius: 5px;
 
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: rgba($secondary-light, 0.6) !important;
        }
    }
    .segtitle{
        font-size: 16px;
        font-weight: 500;
        padding-top: 5px;
       
    }
}
}