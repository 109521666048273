@import "../../../../../sass/variables";

.seg-filter {
  &::-webkit-scrollbar {
    width: 4px;
    // / Scrollbar width /
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--primary-dark);
  }

  .select-seg-list {
    &.active {
      .btn {
        // Added active class
        // border-color: $primary !important;
        // background-color: rgba($primary, 0.1) !important;

        &:hover {
          color: $black !important;
          svg {
            path {
              stroke: $black !important;
            }
          }
        }
      }
    }
    .btn {
      &:hover {
        color: $primary !important;
        // border-color: $primary !important;
        // background-color: ;

        svg {
          path {
            stroke: $primary !important;
          }
        }
      }
    }
  }

 
}

.pro-details-section .seg-progress-details {
  display: flex;
  align-items: baseline;
  gap: 6px;
  justify-content: space-between;
  padding: 2px 12px;
}

.pro-details-section .seg-progress-details .seg-color-code {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  display: inline-block;
}

.search-input {
  input {
    font-size: 14px;
    padding: 6px 12px;
  }

  .search-icon {
    right: 8px;
    top: 2px;
    z-index: 9999;
    i {
      font-size: 12px;
    }
  }
}
