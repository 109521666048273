@import './sass/variables';

// Define solid color values for Bootstrap
$primary: #6440FA; // Replace with your actual primary color
$secondary: #f7f7f7; // Replace with your actual secondary color

// Import Bootstrap with overrides

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);

@import "~bootstrap/scss/bootstrap";

// Define CSS variables for theme colors
:root {
  --primary: #{$primary};
  --secondary: #{$secondary};
  --background: #ffffff; // Light mode background
  --text: #000000; // Light mode text
}

// Dark mode variables
.dark-mode {
  --background: #1a1a1a; // Dark mode background
  --text: #ffffff; // Dark mode text
}

body {
  background-color: var(--background);
  color: var(--text);
  transition: background-color 0.3s, color 0.3s;
}

// Your custom styles here

/*====== Body ============*/
$font-family-base:"Manrope", sans-serif !important;
$font-size-base: 0.875rem;
/*====== Navbar ============*/
$navbar-light-hover-color: var(--primary);
$navbar-light-active-color: var(--primary);
$navbar-nav-link-padding-x: 1rem;
$nav-link-font-weight: 600;
$border-radius:0.5rem;
/*====== Hedding ===========*/
$headings-font-family:"Poppins", system-ui;
$h1-font-size:3rem;
$h2-font-size:2.5rem;
$h3-font-size:2rem;
$h4-font-size:1.5rem;
$h5-font-size:1rem;
$h6-font-size:0.875rem;
.fs-11{
  font-size: 11px;
}
$headings-font-weight:600;
/*====== Button ============*/
$btn-white-space: nowrap;
$btn-padding-y: 0.5rem;
$btn-padding-x: 1rem;
$btn-border-radius:0.5rem;
$min-contrast-ratio: 2.0 !default;
$btn-active-box-shadow:none;
$btn-font-weight:600;
$btn-font-size:0.875rem;
$box-shadow: 0 .5rem 1rem rgba($black, .05);
$dropdown-box-shadow: 0 0 16px rgba($black, 0.1);
/*====== Form Controls ============*/
$input-padding-y: 0.5rem;
$input-padding-x: 1rem;
$input-border-radius: 0.5rem;
$form-label-margin-bottom:2px;
$form-label-color: var(--text);
$input-color: var(--text);
$input-placeholder-color: rgba(var(--text), 0.3);
$input-disabled-bg: var(--secondary);
$input-disabled-border-color: var(--secondary);
$form-check-label-color: var(--text);
/*====== Modals ============*/
$modal-sm: 360px;
$modal-md: 500px;
$modal-lg: 600px;


@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import "../node_modules/bootstrap/scss/bootstrap";
