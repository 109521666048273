@import "../../sass/variables";

.sharepage-section {
  // padding-top: 140px;
  display: flex;
  align-items: center;
  justify-content: baseline;
  height: 100vh;


  .btn-gen {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--border);
    span {
      border: 1px solid var(--border);
      border-radius: 6px;
      padding: 4px 12px;
    }
  }
  .share-img {
    padding-top: 20px;
    padding-bottom: 20px;
    .social-icon{
      img{
        cursor: pointer;
      }
    }
  }
}
