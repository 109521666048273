@import "../../sass/variables";

.proimg {
    position: relative;
    display: inline-block;
   
    
    img {
      display: block;
      width: 100%; 
      height: auto; 
    
    }
  
    .pro-act {
      display: none!important;
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      justify-content: space-between;
      align-items: center;
      padding-left: 5px;
      padding-right: 5px;
   
     
    }
  
    &:hover {
        img {
             opacity: 0.7 ;
             transition: opacity 0.5s ease; // Smooth transition for opacity change 

          }
      .pro-act {
        display: flex !important;
      }
    
    }
  }
  
  .proimg {
    &:hover{
        background-color:rgba($black, 0.8) !important;
        z-index: 1!important;
        border-radius: 10px;
    }
  }

.pro-act {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .pro-edit-btn {

    .edit-btn {
   
        padding: 13px 25px;
     text-decoration: none;
        background-color:rgba($dark-gray, 0.9) !important;
        border: 1px solid rgba($dark-gray, 0.9)!important;

        &:hover {
            border: 1px solid $white!important;
        }
    }
  }
  
  .pro-act-btn {
    .btn-ba {
     border-radius: 50px;
     width: 45px;
     height: 45px;
     margin-left: 8px;
     background-color: rgba($dark-gray, 0.9)!important;
     border: 1px solid rgba($dark-gray, 0.9)!important;
      i {
        font-size: 14px; 
        color: #fff;

        
      }
  
      &:hover {
        background-color: rgba($dark-gray, 0.9)!important;
        border: 1px solid $white!important;
      }
  
      &:active {
        background-color: #555 !important; 
      }
    }
  }
  


