.from-btn{
    background-color: #6d455a !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 6px 12px !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
}
.from-save-btn{

    background-color: #293849 !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 6px 12px !important;
    border-radius: 4px !important;
    border: 1px solid transparent;
}
.btn-from{text-align: end; margin-top: 25px;}
.radio-btn .css-1cqh9jq{margin-right: 20px; }
/* .radio-button-from .css-1cqh9jq{
background-color: #fff;

} */
.radio-button-from{padding-top: 6px; padding-bottom: 6px;}
.radio-button-from  .css-vtg3j9{    border-color: #293849 !important;
}
.from-btn{    margin-right: 16px;}
.buttonradio{padding-left: 10px;}