@import "../../sass//variables";

.wrapper{
    height: 100%;
    padding-left: 250px;
    transition: all 0.2s ease-in-out;
    &.active{
        padding-left: 106px;
        
        .sidebar{
            width: 106px;
            .sidebar-menu{
                .sidebar-header{
                    .logo{
                        display: none;
                    }
                }
                .sidebar-nav{
                    li{
                        .sidebar-nav-text{
                            display: none;
                        }
                        &.active{
                            .submenu{
                                position: absolute;
                                left: 60px;
                                top: 0;
                                padding: 10px;
                                background-color: $white;
                                border-radius: 8px;
                                box-shadow: 0 6px 16px rgba($black, 0.1);
                            }
                        }
                        
                    }
                }
            }
        }

        
    }
}

.list-card{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    list-style: none;
    li{
        width: calc(100% / 5 - 1rem);
        background-color: $primary;
        margin: 0.5rem;
        width: 100;

        img{
            aspect-ratio: 2/1;
            object-fit: cover;
        }
    }
}
