@import "../.././../sass/variables";

.gen-ai-page{
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
  .custom-back-button {
    background-color: rgba($black, 0.1); // Primary color for the button
    border-radius: 50px; // Rounded corners
    transition: background-color 0.3s, transform 0.2s; // Smooth transition effects
      font-size: 14px;
    &:hover {
        background-color: #0056b3; // Darker shade on hover
        // transform: scale(1.05); // Slightly enlarge on hover
    }
}
.gen-ai-section {
  width: 70%;
  margin: 0 auto;
  
  .gen-ai-img {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 6px;
    img {
      border-radius: 6px;
    }
  }
  .gen-ai-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .gen-ai-btn {
      display: flex;
    //   margin-right: 15px;
      align-items: center;
      cursor: pointer;
      padding: 3px 12px;
          &:hover{
            background-color: rgba($primary, 0.20);
            padding: 3px 12px;
          }
      svg {
        path {
          fill: $primary;
        }
      }
      span {
        font-size: 16px;
        padding-left: 10px;
      }

    }
    .switch-btn {
      border: 1px solid $primary;
      .form-switch {
        .form-check-input {
          width: 40px;
          height: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  .pro-gallery-img {
    img {
      box-shadow: 0 6px 16px rgba($black, 0.1);
      border-radius: 6px;
    }
  }
}
}

.social-icon{
  cursor: pointer;
}

.re-anno-section{
  .custom-back-button {
    // background-color: rgba($black, 0.4); // Primary color for the button
  background: rgba(0, 0, 0, 0.5);

    border-radius: 50px; // Rounded corners
    transition: background-color 0.3s, transform 0.2s; // Smooth transition effects
      font-size: 14px;
      box-shadow: 0 4px 14px rgba(0, 0, 0, 0.1) !important;

    &:hover {
        background-color: rgba($primary, 1); // Darker shade on hover
  background: rgba(0, 0, 0, 0.4);

        // transform: scale(1.05); // Slightly enlarge on hover
    }
}
}

.save-anno-btn{
  left: 12%;
  top:10px;
    .btn{
      padding: 10px 24px;
      &:hover{
        background-color: rgba($secondary, 0.8);
      }
    }
}
