// SCSS Spinner Styles

// Keyframes for spinner animation
@keyframes spinner {
    0%, 57.14% {
        animation-timing-function: cubic-bezier(0.33, 0.66, 0.66, 1);
        transform: translate(0);
    }
    28.57% {
        animation-timing-function: cubic-bezier(0.33, 0, 0.66, 0.33);
        transform: translateY(-6px);
    }
    100% {
        transform: translate(0);
    }
}

// Spinner Circles
.spinner {
    &.qM83 {
        animation: spinner 1.05s infinite;
    }

    &.oXPr {
        animation: spinner 1.05s infinite;
        animation-delay: 0.1s; // Delay to stagger animation
    }

    &.ZTLf {
        animation: spinner 1.05s infinite;
        animation-delay: 0.2s; // Delay to stagger animation
    }
}
