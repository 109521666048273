
@import "../../../../sass/variables";

.add-seg{
    .form-check-input{
        cursor: pointer;
    }
    .form-check-label{
        color: $secondary;
        font-weight: 600;
      
    }

}



.dot-icon{
    cursor: pointer;
}

.select-seg{
    border-color: rgba($primary, 1);
}

.bred-group{
    border: 1px solid rgba($primary, 0.2);
    padding: 10px 10px 12px 10px;
    border-radius: 6px;
}
.options{
    ul{
        padding: 0;
        margin-top: 15px;
        
        li{
            
            list-style: none!important;
            padding: 5px;
            cursor: pointer;
            &:hover{
                background-color: rgba($primary, 0.06);
                box-shadow: 0 1px 3px 0 rgba(0,0,0,.1);
            }

            &.active-icon{
                background-color: rgba($primary, 1);
                color: #fff;
            }


        }
    }
}

.btn-close{
    padding: 0;
    margin: 0!important;

}

.seg-popover{
width: 240px;
inset: -50px auto auto 195px!important;
.popover-arrow::before{
    display: none;
}
.popover-arrow::after{
    display: none;
}
    .popover-body{
  
        padding: 8px 0 8px 0px !important;
    }

.seg-anno-act{
    // padding: 0 32px;
    padding: 0;
    margin:0;
    li{
        list-style: none!important;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 18px;
        cursor: pointer;
        
        &:hover{
            background-color: rgba($light-primary, 0.06);
            // box-shadow: 0 2px 5px rgba($black, 0.1);
        }
        &.active{
            background-color: rgba($primary, 1);
        }
        i{
            padding-right: 10px;
            // color: rgba($primary, 0.8);
        }
    }
}
}



.accordion-seg-list{

    .accordion-button{
        background-color: $white;
        &:not(.collapsed) {
        background-color: rgba($primary, 0.1);
      color: $primary;

      i{
        color: rgba($primary, 1);
       &:hover{
        color: rgba($primary, 1);
        // border: 1px solid rgba($primary, 1);
        ;
       }
      }
        }
    }
    .addseg-icon{
        
        display: flex;
        justify-content: end;
        width: 100%;
        color: rgba($primary, 1);
       
       i{
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        font-size: 24px!important;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid rgba($primary, 0.3);
        &:hover{
            background-color: rgba($primary, 0.7)!important;
            color:$white!important;
        }
  
       }

  
}
    .accordion-button::after{
        position: absolute;
        right: 55px;
    }
}


.layer-popover{

    width: 240px;
inset: -80px auto auto 15px!important;
}


.breadcrumb-section{
    border: 1px solid rgba($light-primary, 0.15);
    padding: 10px 10px;
    border-radius: 5px;

    .breadcrumb-item{
        font-size: 14px;
        a{
            font-weight: 500;
        }
    }
}

.seg-index-btn{
    padding: 10px 20px;
    background-color: rgba($light-primary, 0.06)!important;
    
    .collapsed{
        background-color: transparent!important;
    }
    font-weight: 600!important;

}

.seg-groups-title{
    padding: 10px 20px;
    font-weight: 500;

    &:hover{
    background-color: rgba($light-primary, 0.04);
    }
}