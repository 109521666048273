.createproject-pages .Container{    min-height: 59vh!important;
}
@media (min-width: 250px) and (max-width: 767px) {
    .project-loading-div{    display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        justify-content: center;}
        .create-project-shimmer{margin-bottom: 25px!important;}
}

.upload-create-pro{margin: 0!important;}

.media-images .bi-house-add-fill{    font-size: 120px;
    text-align: center;
}

.cre-btn{    background: #6d455a!important; color: #fff!important;} 
.cre-btn:hover{    background: #293849!important; }