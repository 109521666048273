.left-section {

    background-color: #f4f4f4 !important;
    border-radius: 0px;
    border-right: 2px solid #ddd;
   /* height: 100%!important; */
}


/* .left-sec-content{font-size: 16px; font-family: "Poppins", sans-serif;} */
.project-title {
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-align: center;
    font-weight: 500;
}

.card-list {
    border-bottom: 1px solid #ddd;
}

.list-card {
    list-style: none;
}

.czlRJs svg path {
    fill: rgb(142, 142, 169) !important;
}

/* .card-list-title:hover{    background: #ddd;
    width: 200px;
    padding-top: 12px;
    padding-left: 0px;
    border-radius: 3px;} */

.card-list-title:hover {
    background: #fff;
    width: 252px;
    padding-top: 12px;
    padding-left: 0px;
    border-radius: 3px;
    box-shadow: 0px 2px 5px -3px;
}

.card-list-title {
    width: 200px;
    padding-top: 12px;
    padding-left: 0px;
    border-radius: 3px;
    cursor: pointer;
}

.card-list-title svg path {
    fill: rgb(142 142 169);
}

.project-title {
    color: rgb(102, 102, 135);
}

.card-list-title:hover svg path {
    fill: rgb(102, 102, 135);
}

.project-title:hover {
    color: rgb(102, 102, 135);
}

.list-card {
    padding: 0 19px;
}

.left-content {
    padding: 0 !important;
}

/* 
.card-list-title{ 

    background-color: ;
} */

.pro-list {
    cursor: pointer;
}

.left-section {
    /* height: 690px; */
    height: calc(100vh - 94px);
    overflow: auto;
}

    /* height: calc(100vh - 70px); 
    overflow: auto; */


.accordion-pro {
    font-size: 16px;
    color: #6d455a;
    font-weight: 500;
    text-align: justify;
}

.media-list-pro {
    color: rgb(102, 102, 135);
    font-weight: 700;
    font-size: 16px;
}

/* .accordion-item {
    border: none !important;
    
} */

.data-list:hover {
    box-shadow: 0px 2px 5px -3px; 
    background-color: #fff!important;
    border-radius: 3px;
} 

.data-list {
    border: none;
    background-color: #f4f4f4!important;
    border-radius: 3px;
}

/* .list-library{background-color: #fff; border: 1px solid #ccc; border-top: none!important;} */

.list-library ul li:hover {
    background: #eaeaea !important;
    padding: 9px 0;
    border-radius: 3px;
    color: #6d455a;
    box-shadow: 0px 2px 5px -3px; 
}

.media-list-pro i{padding-right: 10px;}