@import "../../../../sass/variables";

.segment-select-tool-list {
    background: #fff;
    border-radius: 0;
    list-style: none;
    padding: 4px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    li{
        padding: 8px;
        border-right: 1px solid rgba(255,255,255,0.5);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:last-child{
            border: none;
        }
        &:hover{
            background-color: rgba(0,0,0,0.2);
        }
    }
}

.segdata-title{
    font-size: 1.2rem;
}
.seg-data{ 
    .bi-copy{
        cursor: pointer;
        padding-left: 10px;
        font-size: 14px;
    }
    .boundingBox-data-text{
        word-break: break-all;
    }        
}




.segment-tab-menu{
    .tab-navigation{
        .swiper{
            background-color: $white!important;
        }
     }
    
    .seg-tab-menu-list{
     display: flex;
     align-items: center;
     justify-content: space-between;
    
     .swiper{
        margin: 0;
        background-color: rgba($primary, 0.2);
    }
    .house-icon{
       
        width: 46px;
        height: 46px;
        text-align: center;
        border-left: 1px solid #dee2e6;
        display: flex;
        align-items: center;
        justify-content: center;
     
        .bi{
        font-size: 18px;
        border: 1px solid #ddd;
        padding: 3px 8px;
        border-radius: 4px;
        background-color: rgba($primary, 0.8);
        color: #fff;
        cursor: pointer;
    }
        
    }

}
}

/* Custom Scrollbar */
.segmentview-details {
    /* width of the scrollbar */
    ::-webkit-scrollbar {
      width: 4px;
    }
  
    /* Track of the scrollbar */
    ::-webkit-scrollbar-track {
     
        // background: lightblue; 
        background-color: rgba($primary, 0.3);
        
    }
  
    /* Handle of the scrollbar */
    ::-webkit-scrollbar-thumb {
        background:   $primary; 
        border-radius: 10px;
    }
  
    
    // ::-webkit-scrollbar-thumb:hover {
    //   background: #b30000;
    // }
  }

//   .seg-pallet-bg{
//     background-image: url(https://dzinlyv2.s3.us-east-2.amazonaws.com/liv/materials/Belden__Tulip_Blend_NjI5ODA2.jpg);
//     background-size: contain;
//   }



  .annotation-editor{
    background-color: red;
  }
  
  .swichpallets-boxs{
    ul{
        list-style: none;
        padding: 7px 18px;
        margin: 0;

        li{
            cursor: pointer;
            // padding: 8px 12px;
            // border-radius: 4px;
            
            font-weight: 600;
            &.active{
                // background-color: rgba($primary, 0.2);
                // border-bottom: 1px solid var(--primary);
                color: var(--primary);
            }
        }
       
    }

  }