@import "../../../../sass/variables";
.loding-icons{
    position:absolute;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0,0,0,0.5) !important;
    color: $white;

        .loading-img{
          vertical-align: top;
          fill:$white;
        }
}