@import "../../../sass/variables";


.seg-group-box{ 
    // border-top: none!important;
.layer-section{
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: var(--background);
    margin-top: 6px;
     .bi{
        color: var(--primary);
     } 
}

}
.seg-tab-list {
    .nav-item {
        width: 50%;

        .nav-link {
            width: 100%;
        }
    }

}

.popover-layers{
    width: 220px;
    inset: -50px auto auto 145px !important;
}




