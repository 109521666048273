@import "../../sass/variables";

.get-startd-top{
    width: 100%;
}
.beforeafter{
    .beforeafter-btn{
        backdrop-filter: blur(25px);
        background: hsla(0, 0%, 5%, .5);
        border-radius: 160px;
        left: 50%;
        padding: 4px;
        position: absolute;
        top: 387px;
        transform: translateX(-50%);
        user-select: none;
        .btn-ba{
            height: 37px;
            width: 77px;
            z-index: 2;
            position: relative;
            &.active{
                color: $primary;
                & + .glider{
                    transform: translateX(77px);
                }
            }
        }
    }
    .glider {
        position: absolute;
        display: flex;
        height: 37px;
        width: 77px;
        background-color: $white;
        z-index: 1;
        border-radius: 99px;
        transition: 0.25s ease-out;
        top: 0;
    }
    img{
        &.active{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            animation: fadeInAnimation ease-in-out 0.25s;
        }
    }
}


@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
