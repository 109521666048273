@import "../../../sass/variables";

/* Custom Scrollbar */
.seg-accordion {
    height: calc(100vh - 104px); /* Dynamic height calculation */
    overflow-y: auto; /* Ensure vertical scrollbar appears */
    background-color: var(--secondary);

    /* Custom Scrollbar */
    &::-webkit-scrollbar {
        width: 4px; /* Scrollbar width */
    }

    &::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--primary);
    }

    &::-webkit-scrollbar-thumb:hover {
        background: var(--primary-dark);
    }
}

/* Accordion Styles */
.seg-accordion {
    .accordion-item {
        margin: 8px;
        border: 1px solid var(--border);
        border-radius: 6px;

        .accordion-header {
            display: flex;
            align-items: center;
            position: relative;

            &:not(.collapsed) {
                background-color:rgba($light-primary, 0.1);
                border-radius: 6px 6px 0 0;

                i {
                    color: #000 !important;
                }
            }

            .icon {
                border: 1px solid #dee2e6;
                padding: 4px;
                border-radius: 5px;

                svg {
                    path {
                        stroke: var(--primary) !important;
                    }
                }

                &:not(.collapsed) {
                    background-color: #fff;
                }
            }

            .seg-item-title {
                font-size: 16px;
                font-weight: 700;
            }

            .dropicon {
                position: absolute;
                right: 12px;
                top: 15px;
                color: var(--primary);
                cursor: pointer;
            }
        }
    }
}

/* Group List Styling */
.seg-group-list {
    li {
        list-style: none;
        border-radius: 0 0px 10px 10px;


        .seg-group-box{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 500;
            padding: 12px 7px 12px 56px;
            // cursor: pointer;
            position: relative;
            cursor: pointer;

            &:hover {
                background-color: rgba(var(--primary), 0.04);
            }

            .add-icon, .add-segicon {
                margin-right: 5px;

                i {
                    border: 1px solid rgba($primary, 0.3);
                    padding: 0 3px;
                    font-size: 18px;
                    border-radius: 5px;
                    color: var(--primary);
                }
            }

            .drop-down-icon {
                position: absolute;
                left: 17px;
            }

            .drag-icon {
                font-size: 18px;
                cursor: move;
            }
        }
     
    }
}

.seg-group-box {
    transition: transform 0.2s ease, opacity 0.2s ease;
  }
  
  .seg-group-box.dragging {
    opacity: 0.5;
    transform: scale(1.05);
  }
  
  .drag-placeholder {
    height: 2px;
    background-color: #007bff;
    transition: all 0.2s ease;
  }


