.Breadcrumb-link{
    color: #2d3748;
    font-weight: 400;

}

.breadcrum-section{justify-content: center;
    display: flex;
    align-items: center;}

    .search-bar-btn{
        border-bottom: 2px solid #ddd;
    }

    .drop-down-menu{
        width: 218px;
    }

    .drop-down-menu .dropdown-divider{
        color: #fff;
    }