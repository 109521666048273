@import "../../../../sass/variables";
.hover-shadow:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .user-group {
    border-radius: 0px 15px 15px 15px;
    border: 1px solid $primary;
  
    .group {
      // When hovering over the group, display the user info
      &:hover {
        .user-info-container {
          display: block!important; // No need for !important unless something else is overriding it
        }
      }
    }
  
    .avatarCircle {
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary; // Add a background color for visibility
      color: white; // Text color for contrast
      border-radius: 50%; // Ensure the avatar is a circle
    }
  
    .user-info-container {
      display: none!important; // Hidden by default
      padding: 0.5rem; // Add padding for a nice layout
      background-color: #fff; // Ensure the background is white
    
      border-radius: 0 0 15px 15px; // Rounded bottom corners
  
      p {
        margin: 0;
        font-size: 0.875rem; // Slightly smaller font size
      }
  
      .small {
        font-size: 0.75rem; // Muted text (e.g., for email or date)
        color: #ddd;
      }
    }
  }
  

  
  .comment-sections{
    position: absolute;
    top: 50px;



.Comment-sec{
   position: absolute;
   left: 35px;
    border: 2px solid $primary;
    border-radius: 0px 15px 15px 15px;
    width: 300px;
    text-align: center;
    .comment-form{
        background-color: rgba($primary, 0.06)!important;
        border-radius: 6px;
        border: none;
        height: 130px;
       
    .from-textarea{
        background-color: transparent!important;
        border: 0;
    }

  


.active-user{
    
    background: $primary !important;
    width: 23px;
    height: 23px;
    color: #fff;
}   
}
.check-btn{
    padding: 0px 4px;
}
}

}

.UserAvatar{
  background:$white;
    padding: 7px 7px!important;
    border-radius: 0px 50px 50px 50px!important;
    border: 2px solid rgba($primary, 0.9);
    border: 1px soldi $primary;
}

.avatarCircle{
  width: 30px!important;
  height: 30px!important;
  display: flex!important;
  align-items: center!important;
  background: rgba($primary, 0.06)!important;
  justify-content: center!important;
  color: #000 !important;
}

.command-details{

  padding-left: 10px;
  padding-right: 10px;

.command-mass{
  display: flex;
  padding-left: 34px;
  font-weight: 700;
}
}


.comment-outline{
  background-color: rgba($primary, 0.03)!important;
  position: absolute;
  width: 89%;
  bottom: 16px;
  border-top: 1px solid var(--border)!important;


}


.comment-header{
  border-bottom: 1px solid $primary;
  display: flex;
  align-items: center;
  justify-content: end;
    
  span{
    i{
  cursor: pointer;
    }
  }
}


.tag-user{
  font-size: 16px;
  padding: 0px 5px;
  &:hover{
      background-color: #ddd!important;
      padding: 0px 5px!important;
      color: #000;
  }
}

.avatar-Circle{
  width: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: baseline;
  background: var(--primary) !important;
  justify-content: center;
  color: #fff !important;
  padding-top: 1px;
}

.user-dropdown{
  width: 150px!important;
  top: 31px;
  border-radius: 6px;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 8px;

  li{
      font-weight: 500;
      font-size: 12px;
      &:hover{
          background-color: $primary;
          color: #fff;
          font-size: 12px;
     
      }
  }
}