.shine {
    background: #f6f7f8;
    background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    animation-duration: 1s;  // Use 'animation' instead of '-webkit-animation'
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }
  
  /* Make sure to keep the -webkit- version for Safari compatibility */
  @-webkit-keyframes placeholderShimmer {
    0% {
      background-position: -468px 0;
    }
    100% {
      background-position: 468px 0;
    }
  }

  
  
  .seg-btn-shimmer {
    height: 45px;
    width: 65px;
    margin-right: 6px;
    /* Add animation if missing */
    animation: placeholderShimmer 1s infinite linear;
  }
  
  .segs-button-shimmer {
    height: 45px;
    width: 50px;
    margin-right: 5px;
  }
  
  .segs-action-shimmer {
    border-radius: 6px;
    height: 40px;
    width: 40px;
    margin: 4px;
  }
  
  .shimmer-shine-action {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 20px;
  }
  
  .seg-btn {
    height: 45px;
    width: 45px;
    margin-bottom: 6px;
    border-radius: 6px;
  }
  
//   .shine-shimmer {

//     padding: 0 15px;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     width: 100%;
//   }
  
  .segs-text-shimmer {
    height: 15px;
    width: 200px;
    margin-top: 10px;
  }
  
  .shimmer-icons {
    padding-left: 10px;
    border-left: 1px solid #dee2e6;
  }
  
  .pallet-shimmer {
    height: 15px;
    width: 70px;
  }
  
  .shimmer-icon {
    height: 35px;
    // margin-top: 10px;
    width: 35px;
    border-radius: 3px;
  }
  
  .text-shimmer {
    height: 10px;
    margin-top: 10px;
    width: 100px;
  }
  
  .pallet-img-shimmer {
    height: 80px;
    width: 100px;
    margin-left: 8px;
    border: 1px solid #dee2e6;
    border-radius: 3px;
    margin-bottom: 10px;
  }
  
  .pallet-shimmer-images {
    margin-top: 15px;
  }
  
  .left-section-img {
    width: 400px;
    border-right: 1px solid #dee2e6;
  }
  
  .seg-shimmer {
    display: grid;
    padding-right: 10px;
    padding-left: 10px;
    height: 90px;
  }
  .tab-con-shimm{border-left: 1px solid #dee2e6;}

  
  .seg-icon-shimmer {
    height: 40px;
    width: 40px;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
  }
  
  .shimmer-shine-button {
    display: flex;
    height: 45px;

  }
  
  .shimmer-box-shine {
    display: flex;
  }
  
 
  