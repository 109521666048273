@import "../../sass/variables";
nav{
    backdrop-filter: blur(25px);
}
.header-user{
    .user{
        width: 32px;
        height: 32px;
        min-width: 32px;
        border-radius: 50%;
        border: 1px solid rgba($black, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }
    }
}

.dropdown-plan-section{
   
          
   .dropdown-plan-sec{
     &::after{
        display: none;
    }


.free-trial-plan-btn{
    border: 1px solid $primary;
    border-radius: 50px;
    padding: 7px 20px;
            &:hover{
                border: 1px solid $primary;
            }
      svg{
        background-color: $primary;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 10px;
           
          path{
            color: $white;
          }
      }
    .start-text{
        font-size: 16px;
        
    }
}
}


.free-trial-btn{
    border: 1px solid $primary;
    border-radius: 50px;
    padding: 7px 20px;
            &:hover{
                border: 1px solid $primary;
            }
      svg{
        background-color: $primary;
        width: 20px;
        height: 20px;
        padding: 4px;
        border-radius: 10px;
           
          path{
            color: $white;
          }
      }
    .start-text{
        font-size: 16px;
        
    }
}
}
.header-active{
    backdrop-filter: blur(25px);
    background-color: rgba($white,0.8);
    box-shadow: 0 1px 1px rgba($black, 0.08);
}

