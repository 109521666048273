@import "../../../sass/variables";



.loginimg{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.logoicon{
    margin: 0 auto;
}

.login-box{
    max-width: 300px;
    width: 100%;
    .form-control-pass{
        padding-right: 40px;
        position: relative;
    }
    .eye-icon{
        height: 100%;
        button{
            border: none;
            background-color: transparent;
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: rgba($black, 0.5);
        }
    }
}