.seg-from {
    background-color: transparent;

}

/* border: 1px solid #cbd5e0;

border-radius: 8px; */

.seg-from-tab{

    border: 1px solid #cbd5e0;
    border-radius: 5px;
}
.accordion-item-seg{border: none!important;}

.input-seg {
    background-color: #fff !important;
}

.from-segmation {
    padding: 12px 12px 12px 12px;
}

.add-entry-btn {
    width: 100%;
    border: 1px solid #ddd !important;
    border-radius: 0px !important;
    height: 50px !important;
    background-color: transparent !important;

}

/* .add-entry-btn:hover{background-color: #f6f6f9!important;} */

/* .from-segmation{background-color: red;} */

.accordion-item-seg .chakra-accordion__button {
    border: 1px solid #cbd5e0 !important;
    border-radius: 5px 5px 5px 5px;

}

.dropdown-icons-seg {
    width: 90%;
    text-align: justify;
}

.action-icon-segjob {
    width: 10%;
    text-align: end;
}

.action-icon-segjob .bi-trash3-fill {
    margin-right: 13px;
}

/* .acc-seg-btn{    margin: -2px -2px;
    width: 752px!important;} */


.box-primary svg {
    margin: 0 auto;
}

.create-new-btn {
    display: flex;
    text-align: center;
    justify-content: center;
    padding-top: 10px;
    color: #6d455a;
    font-weight: 500;
    font-size: 16px;
}

.tablist-seg img{width: 30px; margin-right: 10px;}

.css-1ss3utg[aria-selected=true], .css-1ss3utg[data-selected]{
    color: #4a5568!important;
    background: #ddd!important;
}

.tablist-seg {    border-radius: 3px!important;
    padding: 5px 19px!important;
    /* height: 39px!important;  */
}

.tablist-seg { 
background-color: #f4f4f4!important; margin-right: 10px;
border: 1px solid rgb(220, 220, 228);
}
.acc-seg-btn:hover{background-color: transparent!important;}

.box-primary{cursor: pointer;}