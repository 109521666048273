@import "../../../../../../sass/variables";

.pro-category{
  position: relative;
   
  &:active{
    .checkicon{
        display: block!important;
    }
  }
    .btn{
        width: 100px;
        &:hover{
            background-color: rgba($primary, 0.1) !important; // Primary color for the button
       
        }
    }
}

.select-brand{
    border: 1px solid rgba($black, 0.1) !important; 
    cursor: pointer;

    &:hover{
        border: 1px solid $primary !important;
    }

    .form-check-label{
        cursor: pointer;
    }
}