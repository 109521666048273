@import "../../../../../sass/variables";

.image-sections {
  .item-container {
    .form-check-input {
      position: absolute;
      opacity: 0;
    }
    .house-angle-img {
      position: relative;
      border: 2px solid #fff;
      svg {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background-color: $primary;
        border: 1px solid $secondary;
        display: none;
        path {
          fill: #fff;
        }
        // display: none;
        // stroke-width: 2;
        // stroke: #fff;
        // stroke-miterlimit: 10;
        // margin: 10% auto;
        // box-shadow: inset 0px 0px 0px #293849;
        animation: fill 0.4s ease-in-out 0.4s forwards,
          scale 0.3s ease-in-out 0.9s both;
        position: absolute;
        left: 40px;
        right: 0px;
        top: 22px;
        bottom: 0;
        padding: 3px;
        // margin: auto;
        // z-index: 1;
      }
      img {
        // border-radius: 10px;
        // width: 100%;
        // // height: 100%;
        // aspect-ratio: 1/1;
        // object-fit: fill;

        width: 100%;
        // height: 100%;
        border-radius: 10px;
        object-fit: fill;
        display: block;
      }
    }
    p {
      border-bottom: 1px solid transparent;
     margin: 0;
      &:hover {
        border-bottom: 1px solid $primary;
        cursor: pointer;
      }
    }
  }

  .item-container input[type="radio"]:checked + label .house-angle-img {
    border: 2px solid $primary;
    border-radius: 10px;
  }

  .item-container input[type="radio"]:checked + label .bi-check2 {
    display: block;
  }
}
