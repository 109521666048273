@import "../../../../sass/variables";

.add-seg-model {
    cursor: pointer;
    text-align: center;

    &:hover {    
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); // Optional shadow effect
        // border-color: var(--primary) !important; // Optional border effect

        .segments-item-title {
            &.disabled {
              color: #999;
          
              &:hover {
                color: #6440fa94; // Color changes on hover
              }
            }
          }
          
    }

    .select-seg-checkbox{
        color: var(--primary);
        position: absolute;
        top: 36px;
        left: 0;
        right: 0;
    }
}


