@import "../../../../../sass/variables";

.seg-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    li {
        list-style: none;

        button {
            width: 40px;
            max-width: 40px;
            height: 38px;
            background-color: #ffffff;
            border: 1px solid rgba(0, 0, 0, 0.1);
            font-size: 18px;
            border-radius: 4px;
            margin-left: 8px;
            &:hover{
                border: 1px solid $primary;
                color: $primary;
            }
        }
       
    }
}