// .stage-img{
//     position: relative;
// .pointer-events-none{
//     position: absolute;
//     left: 0;
//     z-index: 999;
// }
// }

.justify-center {
    justify-content: center;
}
.items-center {
    align-items: center;
}
.w-\[90\%\] {
    width: 90%;
}

.h-\[90\%\] {
    height: 90%;
}
.flex {
    display: flex;
}
.relative {
    position: relative;
}

.h-full {
    height: 100%;
}

img, video {
    max-width: 100%;
    height: auto;
}
img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}
.opacity-40 {
    opacity: 0.4;
}

.h-full {
    height: 100%;
}
.absolute {
    position: absolute;
}
.pointer-events-none {
    pointer-events: none;
}
img, video {
    max-width: 100%;
    height: auto;
}
img, svg, video, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}
.w-full {
    width: 100%;
}
.py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}
.items-center {
    align-items: center;
}
.flex-col {
    flex-direction: column;
}
.min-w-full {
    min-width: 100%;
}
.flex {
    display: flex;
}
// @media (min-width: 640px) {
//     .container {
//         max-width: 640px;
//     }
// }
.container {
    width: 100%;
}