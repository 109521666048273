@import "../../../sass/variables";

.pro-action{
    position: absolute;
    z-index: 1;
    width: 30%;
.act-pro-list{
    list-style: none;
     padding: 0;
     margin: 6px 0;
    li{    
        padding: 5px 10px;
        font-weight: 400;
       cursor: pointer;

       &:hover{
        background-color: $primary;
        color: $white;
       }
      
    }
}
}
