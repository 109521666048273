@import "../../../sass/variables";
.canvas-left-cat {
  border-right: 1px solid rgba($black, 0.1);
  .canvas-left-header {
    padding: 0;
    .backtohome {
      .logo {
        width: 32px;
        height: 32px;
        background: var(--primary);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px;
        border-radius: 4px;
        img {
          width: 100%;
        }
      }
    }
  }
  .canvas-left-body {
    padding: 0;
  }
}

.tab-sub-container {
  padding-left: 57px;
  width: 100%;
  .segment-tab-menu {
    width: 100%;
    background: var(--secondary);
    // border-bottom: 1px solid var(--primary);
    .swiper {
      .swiper-wrapper {
        .swiper-slide {
          padding: 12px 15px;
          cursor: pointer;
          font-weight: 600;
          // &.swiper-slide-active{
          //     color: var(--primary);
          // }
        }
      }
    }
  }
}

.segment-tab-menu {
  .tab-navigation {
    .tab-button {
      &.active {
        border-bottom: 2px solid var(--primary);
        background-color: transparent !important;
        color: var(--primary);
      }
    }
  }
}

.select-svgimg {
  button {
    border: 1px solid var(--secondary);
    &:hover {
      border: 1px solid var(--primary) !important;
      color: $black;
      box-shadow: 0 8px 16px rgba($black, 0.1);
    }
    &.active {
      background-color: rgba($primary, 1);
    }
  }
}

.reload-swatches {
  color: var(--primary);
  cursor: pointer;
}

.each-Seg-cont {
  width: 100% !important;
}

.list-icon {
  font-size: 16px;
}

.pallets-check {
  position: absolute;
  top: 14px;
  left: 14px;
  background: rgba(0, 0, 0, 0.5);
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  // display: none;
  align-items: center;
  justify-content: start;
  display: flex;
  pointer-events: none;
  i {
    padding-top: 1px;
    padding-left: 1px;
  }
}
.pallet-information-dropdown {
    position: absolute;
    top: 0px;
    right: 0;

  .pallet-infomation-icon {
    position: absolute;
    top: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    // display: none;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-top: 1px;
    cursor: pointer;
    i {
      font-size: 8px;
      display: flex;
    }
  }

  .dropdown-menu-end{
    left: 8px !important;
    width: 50px !important;
    min-width: 95px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    padding: 2px 0;
    border-radius: 3px;
    top: 8px !important;
  .pallet-info-list{
    font-size: 12px!important;
    border-radius: 3px;
  
    // &:hover{
    //     color: var(--primary)!important;
    // }
    i{
        padding-right: 6px;
    }
  }
}
}
.bi-copy{
  cursor: pointer;
}

