.media-img img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    cursor: pointer;
    object-fit: cover;
}

.create-project .seg-table-container {
    background-color: #fff;
    margin-top: 50px;
    border-radius: 5px;
    border: 1px solid #edf2f7;
}

.table-head th {
    padding-top: 26px !important;
    padding-bottom: 20px !important;
    font-family: "Poppins", sans-serif !important;
}

/* .table-container{background-color:#f9f9f9 ;} */
.action-icon-table {
    margin-right: 12px;
    cursor: pointer;
}

.trash-icon-media i {
    font-size: 15px !important;
    display: flex;
}

.create-pro-btn {
    display: flex;
    align-items: center;
}

.new-entry-btn {
    display: flex;
    justify-content: right;
    margin-top: 30px;
}

.create-new-entry-btn {
    background-color: #6d455a !important;
    color: #fff !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400 !important;
    padding: 4px 12px !important;
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
}

.brand-title {
    text-align: left;
}

.brand-right-section{margin: 0 auto;}