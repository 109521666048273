// Static color definitions for Bootstrap
$primary: #6440FA;
$black: #000000;
$secondary-light: #ccc;
$secondary: #f2f2f2;
$light-gray: #f2f2f2;
$white: #ffffff;
$dark-gray: #545455;
$pink: #ff0000;
$light-brown: #100101;
$border: #b0b0b0;
$grey: #454C52;
$border-hover: #6440FA;

// Define light theme colors
$light-primary: #6440FA;
$light-background: #ffffff;
$light-text: #222222;
$light-secondary: #f2f2f2;
$light-border: #b0b0b0;
$light-svg-stroke: #000;
$light-text-ondark: #fff;
$light-text-onlight: #000;
$light-border-hover: #6440FA;

// Define dark theme colors
$dark-primary: #afd5aa;
$dark-background: #101827;
$dark-text: #ffffff;
$dark-secondary: #f2f2f2;
$dark-border: #4a4a4a;
$dark-svg-stroke: #6440FA;
$dark-text-ondark: #ffffff;
$dark-text-onlight: #000;
$dark-border-hover: #6440FA;

// Default background (you can change this to $light-background or $dark-background)
$background: $light-background;

// CSS Custom Properties for theme switching
:root {
  --primary: #{$primary};
  --background: #{$background};
  --text: #{$light-text};
  --secondary: #{$secondary};
  --border: #{$border};
  --svg-stroke: #{$light-svg-stroke};
  --text-ondark: #{$light-text-ondark};
  --text-onlight: #{$light-text-onlight};
  --border-hover: #{$light-border-hover};
}

.dark {
  --primary: #{$dark-primary};
  --background: #{$dark-background};
  --text: #{$dark-text};
  --secondary: #{$dark-secondary};
  --border: #{$dark-border};
  --svg-stroke: #{$dark-svg-stroke};
  --text-ondark: #{$dark-text-ondark};
  --text-onlight: #{$dark-text-onlight};
  --border-hover: #{$dark-border-hover};
}

// SASS variables for use in your styles
$primary: var(--primary);
$background: var(--background);
$text: var(--text);
$secondary: var(--secondary);
$border: var(--border);
$border-hover: var(--border-hover);
$svg-stroke: var(--svg-stroke);
$text-ondark: var(--text-ondark);
$text-onlight: var(--text-onlight);

// Bootstrap theme colors
$primary: $light-primary;
$secondary: $light-secondary;
$background: $light-background;
$text: $light-text;
$border: $light-border;
$border-hover: $light-border-hover;
