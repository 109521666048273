.magnifier-lens {
    position: absolute;
    border: 1px solid #000;
    border-radius: 50%;
    cursor: none;
    z-index: 10;
    width: 150px;
    height: 150px;
    overflow: hidden;
    pointer-events: none;
    display: block;
   margin-top: 50px;
  }
  
  .magnifier-content {
    position: absolute;
    
    transform: scale(2); /* Magnification factor */
    transform-origin: top left;
  }

