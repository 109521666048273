@import "../../sass/variables";

// Ensure that this import statement is correct and the path is valid

.editor-wrapper {
    padding-left: 400px;
}

.editor-toolbar-left {
    position: fixed;
    left: 0;
    width: 400px;
}

.editor-canvas{
    width: calc(100vw - 400px);
    // .canvas-container{
    //     width: calc(100vw - 400px) !important;
    //     height: 100vh !important;
    //     canvas{
    //         width: calc(100vw - 400px) !important;
    //         height: 100vh !important;
    //     }
    // }
}

footer{
    display: none;
}
// .navbar{
//     display: none;
// }
.main-body-wrapper main {
    padding-top: 0;
    min-height: 100vh;
}



.left-panel-menu{
    height: calc(100vh - 43px);
    border-right: 1px solid rgba($black, 0.1);
    background-color: $white;
    gap: 12px;
    position: fixed;
    left: 0;
    width: 57px;
    z-index: 99;
    .nav-item{
        .nav-link{
            width: 40px;
            max-width: 40px;
            height: 40px;
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border: 1px solid rgba($black, 0.1);
          
            svg{
                path{
                    stroke: var(--svg-stroke);
                }
            }
            &:hover{
                border: 1px solid $primary;
                background-color: rgba($black, 0.1);
            }
            &.active{
                background-color: $primary;
                svg{
                    path{
                        stroke: $black;
                    }
                }
            }
        }
    }
}

.add-seg-icons{  
    width: 40px;
    max-width: 40px;
    height: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--background);
    border: 1px solid rgba($black, 0.1);
    border-radius: 6px;
    cursor: pointer;
    &:hover{
        border: 1px solid $primary;
    }
    &.active{
        background-color: $primary;
        svg{
            path{
                stroke: $white;
            }
        }
    }
}


.re-anno-section{
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    
.btn{
    // background-color: rgba($secondary, 0.51);
    color: $white;
    padding: 5px 16px!important;
}
}

.btn-group{
    width: 100%;
}

.pro-details-textarea{
    font-size: 12px;
    font-weight: 700;
}
