@import "../../../../src/sass/variables";

.pojects{
    .media-images{
        cursor: pointer;
        aspect-ratio: 1.5/1;
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.edit-input{
    padding: 3px 8px;
    border-radius: 6px;
}



.project-action{
    span{
        margin-left: 5px;
     
        i{
            cursor: pointer;
            padding-right: 4px;
            font-size: 16px;
            &:hover{
                color: var(--primary) !important;
            }
        }
    }
}

.job-content{
    display: flex;
.cre-pro-date{
    font-size: 14px;
    cursor: pointer;
}
}




