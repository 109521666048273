@import "../../../../sass/variables";

.pro-details-sec {
  height: calc(100vh - 104px);
  // / Dynamic height calculation /
  overflow-y: auto;
  // / Ensure vertical scrollbar appears /

  // / Custom Scrollbar /
  &::-webkit-scrollbar {
    width: 4px;
    // / Scrollbar width /
  }

  &::-webkit-scrollbar-track {
    background-color: var(--scrollbar-track);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--primary);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--primary-dark);
  }
}


.pro-details-sec {
  .seg-pro {
    .seg-progress-details {
      display: flex;
      align-items: baseline;
      gap: 6px;
      justify-content: space-between;
      padding: 2px 12px;
      border: 1px solid #ddd;


      &:hover {
        background-color: rgba($primary, 0.09);
        cursor: pointer;
        //  color: #fff;
        border-radius: 6px;
      }
      p{
        font-weight: 500;
        font-size: 12px;
        margin: 0;
      }
      .seg-color-code {
        background-color: var(--primary);
        width: 10px;
        height: 10px;
        border-radius: 20px;
        display: inline-block;
      }
      .total-seg {
        padding-right: 6px;
      }
    }
  }
  .Instru-sec {
    padding-top: 16px;
      
    .edit-instr-icon{
      cursor: pointer;
      
    }
    .instru-text {
      border-left: 1px solid var(--border);
      padding-left: 8px;
    }
  }
  .seg-assi-sec {
    .assign-user {
      font-weight: 600;
    }

    .edit-icon {
      cursor: pointer;
    }

    .assign-text {
      font-size: 12px !important;
    }
  }
}

.assign {
  padding: 10px;

  &:hover {
    background-color: rgba($primary, 0.04) !important;

    .avatarCircle {
      background-color: var(--background); // Use --background if it's a CSS variable
      border: 1px solid var(--primary) !important;
    }
  }

  .userInfo {
    .displayName {
      font-weight: 700 !important;
    }

    .displayEmail {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
    }
  }
}

.add-user-outer {
  font-weight: 700;

  i {
    font-size: 18px;
  }

  &:hover {
    color: var(--primary);
    cursor: pointer;
  }
}

.timeline-section {
  margin-top: 1.5rem;
  padding: 0 1rem;

  h5 {
    margin-bottom: 1rem;
  }

  .timeline-content {
    .timeline-item {
      padding-bottom: 1rem;
      position: relative;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        left: 14px;
        top: 38px;
        bottom: -8px;
        width: 2px;
        background-color: #e5e7eb;
      }

      .avatar-section {
        .user-avatar-circle {
          .avatar-circle-rounded {
            width: 30px;
            height: 30px;
            font-size: 1rem;
            cursor: default;
            background-color: #6440fa;

            p {
              text-transform: lowercase;
            }

            .hover-tooltip {
              display: none;
              bottom: 100%;
              left: 50%;
              transform: translateX(-50%);
              white-space: nowrap;
              font-size: 0.75rem;
              z-index: 1000;
              margin-bottom: 0.5rem;

              &::after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                transform: translateX(-50%);
                border-width: 5px;
                border-style: solid;
                border-color: #212529 transparent transparent transparent;
              }
            }

            &:hover {
              .hover-tooltip {
                display: block;
              }
            }
          }
        }
      }

      .timeline-details {
        .timeline-text {
          font-size: 0.875rem;
          line-height: 1.4;
          color: #1f2937;
        }

        .timeline-date {
          font-size: 0.75rem;
          color: #6b7280;
        }
      }
    }
  }
}