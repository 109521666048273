@import "../../../sass/variables";

.ai-exteriors-sec {
  .aiexter-custo-sec {
    .home-tab, .profile-tab {
      height: calc(75vh - 190px);
      overflow-y: scroll;
      overflow-x: hidden;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--secondary);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 10px;
      }
    }

    .gallery-tab {
      height: calc(100vh - 120px);
      overflow: auto;

      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: var(--secondary);
      }

      &::-webkit-scrollbar-thumb {
        background: var(--primary);
        border-radius: 10px;
      }
    }
  }

  .dropdown-toggle {
    background-color: var(--secondary);
    border: 1px solid var(--border);
    color: var(--text);
  }

  .drop-btn::after {
    position: absolute;
    right: 15px;
    top: 18px;
  }

  .upload-imgs {
    border: 2px dashed rgba($black, 0.1);

    .upload-img-input {
      height: 180px;
    }
  }

  .customize-sec {
    padding-top: 10px;
  }

  .create-btn {
    margin: 0 auto;
  }

  .gen-layout {
    bottom: 0px;
    background-color: var(--background);
    width: 100%;
    position: sticky;
    padding-bottom: 20px;

    .from-textarea {
      width: 99%;
    }

    .switch-checkbox {
      font-size: 12px;
    }

    .inactive {
      background-color: var(--primary);
      border: 1px solid var(--primary) !important;
      opacity: 0.6;
    }
  }
}

.media-img {
  position: absolute;
  top: 10px;
  left: 10px;

  .media-img-title {
    background: hsla(0, 0%, 5%, 0.5);
    backdrop-filter: blur(25px);
    border-radius: 6px;
    padding: 10px 10px;
    margin-bottom: 8px;

    h6 {
      color: var(--text);
      padding: 0;
      margin: 0;
      font-weight: 400;
    }
  }
}
