@import "../../../../../sass/variables";

.design-theme-model{
    min-width: 690px!important;
    .box-item{
        position: relative;
        &:active {
            border: 1px solid $primary;
        }
        img{
            border-radius: 6px;
        }
     p{
        padding-top: 6px;
     }
     // ... existing code ...
    // ... existing code ...
.select-cehckbox {
    position: absolute;
    left: 50%; // Center horizontally
    top: 32%; // Adjust as needed for vertical positioning
    background-color:$white;
    width: 24px;
    height: 24px;
    border-radius: 50px;
    transform: translate(-50%, -50%); // Center the checkbox
    display: flex; // Added to enable flexbox
    align-items: center; // Center vertically
    justify-content: center; // Center horizontally
    i {
        color: $primary; // Keep the icon color
    }
}
// ... existing code ...
// ... existing code ...
    }
}