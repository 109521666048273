@import "../../sass/variables";

.create-project{
    min-height: calc(100vh - 63px);
    .card{
        transition: all 0.2s ease-in-out;
        &:hover{
            box-shadow: 0 12px 24px rgba($black, 0.1);
            h5{
                color: $primary;
            }
        }
    }
}
.bottom_side{
    height: 70%!important; 
.upload{
    cursor: pointer;
}
}

.guide-text{
    ul{
        margin: 0;
        padding: 0;
        list-style: none;
        li{
            padding: 3px 0;
            font-size: 12px;
        }
    }
}

.upload-input{
    min-height: 400px;
    height: 100%;
    img{
        width: 100%;
        height: 100%;
        object-fit:fill;
        margin: 0 !important;
    }
}

.segmentlist{
    margin: 0;
    padding: 0;
    list-style: none;
    li{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0px;
        border-bottom: 1px solid rgba($black, 0.1);
        &:last-child{
            border: 0;
        }
    }
}
