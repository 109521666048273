@import "../../../sass/variables";

.shimmer-card  {
    position: relative;
    // -webkit-box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    background-color: #FFF;
    border-radius: 8px;
    height: 335px;
    overflow: hidden;
    width: 100%;
    // border-color: #f1f1f1;
    border: 1px solid #ddd;

    

  
  .shimmerBG {
    animation-duration: 2.2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: shimmer;
    animation-timing-function: linear;
    background: #f1f1f1;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 1200px 100%;
    
}






@keyframes shimmer {
    0% {
        background-position: -1200px 0;
    }
    100% {
        background-position: 1200px 0;
    }
}
  

    .media {
    height: 280px;
}

.title-line {
    height: 35px;
    width: 40%;
    border-radius: 8px;

}
.sim-icon{
    width: 60%;
    justify-content: end;
}
.end{ 
    width: 20%;
  
}
}

