.media-libary-section .css-1qq679y {
   left: 268px !important;
   border-radius: 0;

}

.model-content {
   background-color: #fff !important;
   border-radius: 0px !important;
   width: 268px !important;
}

.types-title {
   font-size: 12px;
   color: rgb(102, 102, 135);
   text-align: justify;
   padding-top: 10px;

   border-bottom: 1px solid #ccc;
}

.show-type {
   background-color: #ddd;
   padding: 5px 11px;
   border-radius: 3px;
   float: inline-end;
}




.type-title {
   vertical-align: sub;
}

.media-list {
   text-align: start;
}

.media-list ul {
   list-style: disc;
}

.media-list ul li {
   margin: 18px 0;
   cursor: pointer;
}



.model-content {
   height: calc(100vh - 94px);
   overflow: auto;
}

.model-content {
   left: 277px !important;
   border-radius: 0;
   top: -127px!important;
   border: 1px solid #ccc;
}

.popover-content {

   left: 277px !important;
   border-radius: 0;
   top: -170px!important;
   border-radius: 0!important;
   height: calc(100vh - 94px);
   overflow: auto;
   width: 268px!important;

}

.list-title ul {
   list-style: none;
   padding: 0;
}

.list-title ul li {
   width: 100%;
   padding: 9px 0;
   color: #1a202c;
   border-radius: 3px;
   font-weight: 500;
   cursor: pointer;
}

.list-type {
   display: flex;
   align-items: center;
}

.list-title ul li:hover {
   background: #f1f1f1;
   padding: 9px 0;
   border-radius: 3px;
   color: #6d455a;


}

/* #6d455a
 #1a202c */


/* .media-list ul li { list-style: none;} */

.paths-icon{    margin: 0px 14px 0px 13px;}