.compare-image{text-align: center!important;}
.comapare-img{

    --divider-width: 3px!important;
    --default-handle-width: 80px;
}


.before,
  .after {
    margin: 0;
  }

  .before figcaption,
  .after figcaption {
    background: #fff;
    border: 1px solid #c0c0c0;
    border-radius: 6px;
    color: #1a202c;
    opacity: 0.8;
    padding: 12px 13px 9px 13px;
    position: absolute;
    top: 50%;
    font-size: 16px;
    font-weight: 400;
    transform: translateY(-50%);
    line-height: 100%;
    font-family: Poppins, sans-serif;
  }

  .before figcaption {
    left: 12px;
  }

  .after figcaption {
    right: 12px;
  }